import PollIcon from '@mui/icons-material/Poll';

import config from '../../../config';
import Permissions from '../../../shared/enums/Permissions';
import Blacklist from '../../../shared/icons/Blacklist';
import BrandTheme from '../../../shared/icons/BrandTheme';
import CampaignDelight from '../../../shared/icons/CampaignDelight';
import JourneyBuilder from '../../../shared/icons/JourneyBuilder';
import CustomerCube from '../../../shared/icons/CustomerCube';
import DigitalReceipt from '../../../shared/icons/DigitalReceipt';
import IntegrationLog from '../../../shared/icons/IntegrationLog';
import Intelliclose from '../../../shared/icons/Intelliclose';
import Offerio from '../../../shared/icons/Offerio';
import Otp from '../../../shared/icons/Otp';
import Personify from '../../../shared/icons/Personify';
import Retensee from '../../../shared/icons/Retensee';
import RoleManagement from '../../../shared/icons/RoleManagement';
import CommerceConnect from '../../../shared/icons/CommerceConnect'
import Store from '../../../shared/icons/Store';
import UserManagement from '../../../shared/icons/UserManagement';
import Setting from '../interfaces/Setting';
import Integrations from '../../../shared/icons/Integrations';

const { dashboardClassic } = config;

export const settingsTabs = [
  {
    label: 'General Settings',
    path: '/settings',
  },
  {
    label: 'Module Settings',
    path: '/settings/modules',
  },
];

export const generalSettings: Setting[] = [
  {
    icon: UserManagement,
    label: 'User Management',
    path: `/settings/user-management`,
    external: false,
    description: 'Control and administer user access and permissions',
    permissions: [],
  },
  {
    icon: RoleManagement,
    label: 'Role Management',
    path: `/settings/role-management`,
    external: false,
    description: 'Assign and oversee user roles and permissions efficiently',
    permissions: [],
  },
  {
    icon: Store,
    label: 'Store Management',
    path: `store-management`,
    external: false,
    description: 'Add/Update/Remove Stores',
    permissions: [],
  },
  {
    icon: Otp,
    label: 'OTP Configuration',
    path: `${dashboardClassic}/otp-configuration`,
    external: true,
    description: 'Configure your otp messages',
    permissions: [],
  },
  {
    icon: IntegrationLog,
    label: 'Integration Logs',
    path: `${dashboardClassic}/live-reports`,
    external: true,
    description: 'Monitor and track data exchange between systems',
    permissions: [],
  },
  {
    icon: Blacklist,
    label: 'Blacklist Management',
    path: `/settings/blacklist-management`,
    external: false,
    description: 'Restrict access and privileges for flagged customers',
    permissions: [],
  },
  {
    icon: Integrations,
    label: 'Integrations',
    path: `/settings/integrations/channel`,
    external: false,
    description:
      'Effortlessly integrate with your preferred platforms, hassle-free.',
    permissions: [],
  },
  {
    icon: BrandTheme,
    label: 'Brand Settings',
    path: `/settings/brand-theme`,
    external: false,
    description: 'Set theme for Casa’s personify & Customer E-bill Services',
    permissions: [Permissions.PERSONIFY],
  },
];

export const moduleSettings: Setting[] = [
  {
    icon: Retensee,
    label: 'Retensee',
    path: `/settings/modules/retensee`,
    external: false,
    description: "Tailor and fine-tune your loyalty program's configuration",
    permissions: [Permissions.LOYALTY],
  },
  {
    icon: Offerio,
    label: 'Offerio',
    path: `/settings/modules/offer-otp`,
    external: false,
    description: 'Customize and optimize your promotional offerings',
    permissions: [Permissions.OFFERS_MANAGEMENT],
  },
  {
    icon: Intelliclose,
    label: 'Intelliclose',
    path: '/settings/modules/intelliclose',
    external: false,
    description: 'Configure and enhance lead tracking and processing',
    permissions: [Permissions.LEAD_MANAGE],
  },
  {
    icon: Personify,
    label: 'Personify',
    path: `/settings/modules/personify`,
    external: false,
    description: 'Personalize and configure your Personify App',
    permissions: [Permissions.PERSONIFY],
  },
  {
    icon: DigitalReceipt,
    label: 'Digital Receipt',
    path: '/invoice-creator',
    external: false,
    description: 'Create customized digital receipts with ease',
    permissions: [Permissions.INVOICE_CREATOR],
  },
  {
    icon: CustomerCube,
    label: 'Customer Cube',
    path: `/settings/module/customer-cube`,
    external: false,
    description: 'Customize and optimize your customer data analytics',
    permissions: [Permissions.CUSTOMER_CUBE_MANAGE],
  },
  {
    icon: JourneyBuilder,
    label: 'Journey Builder',
    path: `/settings/modules/journey-builder`,
    external: false,
    description: 'Tailor and optimize Journey experiences',
    permissions: [Permissions.JOURNEY_BUILDER_MANAGE],
  },
  {
    icon: CampaignDelight,
    label: 'Campaign Delight',
    path: `/settings/modules/muffler-config`,
    external: false,
    description: 'Tailor and optimize campaign experiences',
    permissions: [Permissions.CAMPAIGN_MANAGE],
  },
  {
    icon: PollIcon,
    label: 'Survey',
    path: '/settings/modules/survey',
    external: false,
    description: 'Tailor and optimize campaign experiences',
    permissions: [Permissions.CAMPAIGN_MANAGE],
  },
  {
    icon: CommerceConnect,
    label: 'Commerce Connect',
    path: '/settings/modules/commerce-connect',
    external: false,
    description: 'Seamlessly manage, sync, and integrate product data across CASA',
    permissions: [Permissions.COMMERCE_CONNECT],
  }
]
